@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(245, 246, 247);
  overflow-x: hidden;
}

@keyframes containerAnimation1 {
  0% {left:0; animation-timing-function: ease-in;}
  15% {left:500px; animation-timing-function: steps(1); }
  15.01% {left:-500px; animation-timing-function: linear;}
  30% {left:0; animation-timing-function: linear;}
  45% {left:500px; animation-timing-function: steps(1);}
  45.01% {left:-500px; animation-timing-function: ease-out;}
  100% {left:500px; animation-timing-function: ease-out;}
}

@keyframes containerAnimation2 {
  0% {left:-500px; animation-timing-function: ease-in;}
  15% {left:0; animation-timing-function: linear;}
  30% {left:500px; animation-timing-function: steps(1);}
  30.01% {left:-500px; animation-timing-function: linear;}
  60% {left:500px; animation-timing-function: steps(1);}
  60.01% {left:-500px; animation-timing-function: ease-out;}
  100% {left:0; animation-timing-function: ease-out;}
}

@keyframes blurAnimation {
  0% {opacity: 1; filter:  drop-shadow(0px 0 0 black) blur(0px); -webkit-filter:  drop-shadow(0px 0 0 black) blur(0px); height: 3px; animation-timing-function: linear; transform: scaleY(1.0);}
  10% {opacity: .4; filter: drop-shadow(3px 0 0 black) blur(3.1px); -webkit-filter: drop-shadow(3px 0 0 black) blur(3.1px); height: 8px; animation-timing-function: linear; text-shadow: 3px 0; transform: scaleY(0.9);}
  20% {opacity: .3; filter: drop-shadow(25px 0 0 black) blur(6.1px); -webkit-filter: drop-shadow(25px 0 0 black) blur(6.1px); height: 16px; text-shadow: 25px 0; animation-timing-function: linear; transform: scaleY(0.6);}
  50% {opacity: .3; filter: drop-shadow(25px 0 0 black) blur(6.1px); -webkit-filter: drop-shadow(25px 0 0 black) blur(6.1px); height: 16px; animation-timing-function: linear; text-shadow: 25px 0; transform: scaleY(0.6);}
  80% {opacity: .4; filter: drop-shadow(3px 0 0 black) blur(3.1px); -webkit-filter: drop-shadow(3px 0 0 black) blur(3.1px); height: 8px; animation-timing-function: linear; text-shadow: 3px 0; transform: scaleY(0.9);}
  100% {opacity: 1; filter:  drop-shadow(0px 0 0 black) blur(0px); -webkit-filter:  drop-shadow(0px 0 0 black) blur(0px); height: 3px; animation-timing-function: linear; transform: scaleY(1.0);}
}

.bg {
  position: absolute;
  background: white;
  max-width: 801px;
  width: 100vw;
  margin: 1px;
  height: 13px;
}

.bg2 {
  position: absolute;
  background: rgb(232,232,236);
  margin: 1px;
  width: 39px;
  height: 13px;
}

.bg3 {
  position: absolute;
  /* background: rgb(232,232,236); */
  background: rgb(232,232,236);
  margin: 1px;
  width: 39px;
  right: 0;
  height: 13px;
}

.bg4 {
  position: absolute;
  border: 1px solid rgb(217,218,220);
  max-width: 801px;
  width: 100vw;
  height: 14px;
}

.triangle {
  position: absolute;
  width: 0; 
  height: 0; 
  margin: 8px 0 0 34px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(80,140,255);
}

.triangle2 {
  position: absolute;
  width: 0; 
  height: 0; 
  margin: 8px 0 0 0;
  right: 34px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(80,140,255);
}

.bar {
  position: absolute;
  background: rgb(80,140,255);
  width: 12px;
  height: 4px;
  margin: 3px 0 0 34px;
}

.lines {
  background-position-x: -60px;
  background-size: 100px 30px;
  background-repeat: repeat-x;
  max-width: 800px;
  width: 100vw;
  position: absolute;
}

.short {
  background-position-y: 6px;
  background-image: linear-gradient(to right, rgb(190, 192, 198) 10%, rgba(255, 255, 255, 0) 0%);
  height: 9px;
  background-size: 10px 30px;
}

.tall {
  background-position-y: 4px;
  background-image: linear-gradient(to right, rgb(190, 192, 198) 1%, rgba(255, 255, 255, 0) 0%);
  height: 11px;
  background-size: 100px 30px;
}

.docs-area {
  width: 100%;
  max-width: 800px;
  box-shadow: 0 1px 3px 1px rgb(60 64 67 / 15%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2.5rem;
}

.container1 {
  width: 500px;
  overflow-y: hidden;
  position: absolute;
  height: 40px;
}
.container1-anim {
  animation-name: containerAnimation1;
  animation-duration: 2s;
}

.container2 {
  width: 500px;
  overflow-y: hidden;
  position: absolute;
  height: 40px;
}
.container2-anim {
  animation-name: containerAnimation2;
  animation-duration: 2s;
}

.blurry {
  opacity: 1;
  /* Add the blur effect */
  overflow-y: hidden;
  position: absolute;
  width: 500px;
  min-height: 40px;
}
.blurry-anim {
  animation-name: blurAnimation;
  animation-duration: 2s;
  height: 40px;
}

.text {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  font-weight: bold;
  overflow-x: hidden;
  text-align: center;
  /*border: 1px solid red;*/
  width: 500px;
}

#selector {
  background: rgb(191 219 254);
  /* top: 10px; */
  width: 160px;
  border: 1px white solid;
  height: 48px;
  opacity: 0;
  margin: 21px auto 0 auto;
  position: absolute;
  transition-property: opacity;
  transition-duration: 2s;
}

.item {
  width: 160px;
  white-space: nowrap;
  align-items: center;
  overflow: visible;
}

#spinner1 {
  position: absolute;
  width: 500px;
  height: 100px;
  overflow-x: hidden;
}

#spinner2 {
  position: absolute;
  width: 500px;
  height: 100px;
  overflow-x: hidden;
}

#spinner-container {
  text-align: center;
  width: 500px;
  height: 15px;
  overflow-x: hidden;
  margin: 25px 0;
}

#shadow-box {
  position: absolute;
  box-shadow: inset 45px 0px 45px 45px rgb(245, 246, 247), inset -45px 0px 45px 45px rgb(245, 246, 247);
  margin: -100px 0 0 0;
  width: 500px;
  height: 300px;
}

#letterbox {
  position: absolute;
  overflow: hidden;
  height: 60px;
  width: 500px;
  margin: 0 auto;
}

#blinking-cursor {
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {border-right: 2px solid #2E3D48;}
  50% {border-right: 2px solid rgba(0,0,0,0);}
}

@-moz-keyframes blink {
  from, to {border-right: 2px solid black;}
  50% {border-right: 2px solid rgba(0,0,0,0);}
}

@-webkit-keyframes blink {
  from, to {border-right: 2px solid black;}
  50% {border-right: 2px solid rgba(0,0,0,0);}
}

@-ms-keyframes blink {
  from, to {border-right: 2px solid black;}
  50% {border-right: 2px solid rgba(0,0,0,0);}
}

@-o-keyframes blink {
  from, to {border-right: 2px solid black;}
  50% {border-right: 2px solid rgba(0,0,0,0);}
}
